
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class Intraday extends Vue {
    @Prop({ type: Boolean, default: false })
    special!: boolean;

    @Prop({ type: Boolean, default: false })
    isSamePrice!: boolean;
}
